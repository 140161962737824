// 
// ─── MOBILE ───────────────────────────────────────
//
.philosophy-container {
  background: var(--lightgrey);
  padding: 20px;

  &>p {
    max-width: 850px;
    text-align: center;
    margin: auto;
  }

  .philosophy-timeline {
    max-width: 600px;
    margin: auto;
    padding: 40px 0;
    position: relative;
    z-index: 1;

    .philosophy-line {
      width: 3px;
      background: var(--primary);
      position: absolute;
      z-index: -1;
      left: 25px;
    }

    .philosophy-timeline-container {
      display: flex;
      flex-direction: column;
      background: var(--lightgrey);
      margin: 40px 0;
      padding: 20px 0;
      opacity: 0;
      transform: translateY(20px);

      .philosophy-timeline-title {
        display: flex;
        align-items: center;

        span {
          display: flex;
          justify-content: center;
          width: 45px;
          margin-right: 20px;
          font-size: 4.4rem;
          line-height: 70px;
          font-weight: 900;
          color: #FFF;
          text-shadow: 8px 8px 20px rgba(0, 0, 0, 0.14);
        }
      }

      p {
        margin: 0;
      }
    }
  }

  .animate-philosophy {
    .philosophy-line {
      animation: timeline 3s linear forwards;
    }

    .philosophy-timeline-container {
      transition: 0.3s;
      opacity: 1;
      transform: translateY(0);

      &:nth-child(3) {
        transition-delay: 0.8s;
      }

      &:nth-child(4) {
        transition-delay: 1.5s;
      }

      &:nth-child(5) {
        transition-delay: 2s;
      }
    }
  }
}

@keyframes timeline {
  from {
    height: 0;
  }

  to {
    height: calc(100% - 80px);
  }
}

// 
// ─── TABLET ───────────────────────────────────────
//
@media screen and(min-width:640px) {
  .philosophy-container {
    .philosophy-timeline {
      padding: 40px;

      .philosophy-line {
        left: 62px;
      }
    }
  }
}

// 
// ─── DESKTOP ───────────────────────────────────────
//
@media screen and (min-width:1024px) {
  .philosophy-container {
    padding: 40px 20px;

    .philosophy-timeline {
      max-width: 1300px;
      margin: 80px auto;
      display: flex;
      justify-content: space-between;
      padding: 0;

      .philosophy-line {
        height: 3px !important;
        top: 70px;
        left: 0;
      }

      .philosophy-timeline-container {
        background: none;
        padding: 0;
        margin: 0 auto auto auto;
        width: 240px;

        .philosophy-timeline-title {
          flex-direction: column;

          span {
            width: initial;
            font-size: 12rem;
            line-height: 150px;
            background: var(--lightgrey);
          }

          h3 {
            margin-top: 60px;
          }
        }

        p {
          text-align: center;
        }
      }
    }
  }

  @keyframes timeline {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
}