@import "./variables.scss";

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}


:root {
  --primary: #FFB100;
  --secondary: #1C1A1A;
  --lightgrey: #FAFAFA;
  --text-grey: #979797;
  --cookie: #FFB100;
  --cookie-hover: #ffc337;
}

.grecaptcha-badge {
  visibility: hidden;
  pointer-events: none;
}

p {
  font-size: $font_size_mobile;
}

.button {
  background: var(--secondary);
  color: #FFF;
  padding: 10px 20px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: none;

  &:before {
    content: " ";
    display: block;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    width: 150px;
    height: 100%;
    top: 0;
    left: -100%;
    transform: rotate(-45deg);
    transition: all .25s ease-in-out;
  }

  &:hover:before {
    left: 100%;
  }
}

.button-color {
  background: var(--primary);
  color: var(--secondary);

  &:before {
    background: rgba(255, 255, 255, 0.2);
  }
}

.hero-banner__subtitle {
  font-size: 20px;
  display: block;
  color: #fafafa !important;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #302d2d;
}

body::-webkit-scrollbar-thumb {
  background-color: #141313;
}

@media screen and (min-width:640px) {
  .hero-banner__subtitle {
    font-size: 30px;
    margin-top: -10px;
  }
}

@media screen and (min-width:1024px) {
  p {
    font-size: $font_size_desktop;
  }

  .hero-banner__subtitle {
    font-size: 35px;
    display: block;
  }
}

.animate-title {
  span {
    &:before {
      width: 35px;
    }
  }
}

@media screen and (min-width:640px) {
  .animate-title {
    span {
      &:before {
        width: 45px;
      }
    }
  }
}

@media screen and (min-width:1024px) {
  .animate-title {
    span {
      &:before {
        width: 60px;
      }
    }
  }
}

picture {
  display: block;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}