// 
// ─── MOBILE ───────────────────────────────────────
//
header {
  z-index: 40;
  display: flex;
  padding: 10px 20px;
  width: calc(100% - 40px);
  align-items: center;
  background: var(--secondary);
  position: sticky;
  top: 0;
}

.menu-logo {
  margin-right: auto;

  svg {
    width: 100px;

    path {
      transition: 0.4s;
      transition-delay: 0.3s;
    }
  }
}

.burger {
  width: 60px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: fixed;
  top: 38px;
  right: 20px;

  span {
    background: #FFF;
    height: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:nth-child(1) {
      width: 60px;
    }

    &:nth-child(2) {
      width: 35px;
      margin-top: 10px;
      margin-left: auto;
    }
  }
}


nav {
  z-index: 100;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: transform .4s cubic-bezier(.785, .135, .15, .86);

  .menu-container {
    background: var(--primary);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    ul {
      padding: 0;
      margin: auto;

      li {
        list-style: none;
        margin-bottom: 15px;
        position: relative;
        transform: translateY(10px);
        opacity: 0;
        transition: 0.4s;

        a,
        div {
          display: block;
          color: var(--secondary);
          text-decoration: none;
          font-weight: 900;
          font-size: 30px;
          z-index: 10;
        }

        div {
          cursor: pointer;
          display: flex;
          align-items: center;

          svg {
            width: 25px;
            margin-left: 15px;
          }
        }

        .submenu-toggle {
          svg {
            transform: rotate(180deg);
          }
        }

        a {
          &:after {
            content: " ";
            background: rgba(0, 0, 0, 0.3);
            width: 0;
            height: 15px;
            position: absolute;
            bottom: 10px;
            left: -10px;
            transition: 0.4s;
            z-index: -1;
          }
        }

        &:not(.menu-subitems):hover>a:after {
          width: 60px;
        }
      }

      .menu-active {
        &:after {
          width: 60px;
        }
      }

      .menu-subitems {
        margin: 0 0 0 15px;
        max-height: 0px;
        overflow: hidden;
        transition-property: max-height;
        transition-duration: 0.4s;

        // &>li:hover a:after {
        //   width: 0;
        // }
      }

      .menu-subitems-active {
        overflow: visible;
        margin-bottom: 10px;
        max-height: 500px;
      }
    }
  }

  .burger {
    span {
      &:nth-child(1) {
        width: 50px;
        transform: translate(5px, 5px) rotate(-45deg);
      }

      &:nth-child(2) {
        width: 50px;
        transform: translate(-6px, -9px) rotate(45deg);
      }
    }
  }
}

.navToggle {
  transform: translateY(0);

  .menu-container {
    ul {
      li {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

// 
// ─── TABLET ───────────────────────────────────────
//
@media screen and (min-width:640px) {
  nav {
    .menu-container {
      ul {
        li {

          a,
          div {
            font-size: 45px;
          }

          a {
            &:after {
              height: 25px;
            }
          }

          &:not(.menu-subitems):hover a:after {
            width: 150px;
          }
        }

        .menu-active {
          &:after {
            width: 150px;
          }
        }

        .menu-subitems {
          li {

            &:nth-child(1),
            &:nth-child(3) {
              &:hover:after {
                width: 70px;
              }
            }
          }
        }
      }
    }
  }
}

// 
// ─── DESKTOP ───────────────────────────────────────
//
@media screen and (min-width:1024px) {
  nav {
    display: flex;

    .menu-container {
      &>ul {
        margin: auto auto auto 165px;
      }
    }

    .actu-container {
      background: var(--secondary);
      width: 500px;
      height: 0;
      transition: 0.4s;
    }
  }

  .navToggle {
    .actu-container {
      height: 100vh;
      transition-delay: 0.2s;
    }
  }
}