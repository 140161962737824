// 
// ─── MOBILE ───────────────────────────────────────
//
.home-container {
  background: var(--secondary);
  height: calc(100vh - 94px);
  overflow: hidden;
  display: flex;

  .home-slider {
    margin: auto auto 60px 0;
    z-index: 10;
    color: #FFF;
    padding: 20px;
    max-width: 500px;

    .home-title {

      h1,
      h2 {
        font-size: 24px;

        span {
          color: var(--primary);
        }
      }
    }

    .home-slide-count {
      color: var(--text-grey);

      span {
        &:nth-child(1) {
          color: #FFF;
          margin-right: 5px;
        }

        &:nth-child(2) {
          margin-left: 5px;
        }
      }
    }
  }

  .home-contact {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 190px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    z-index: 10;
    overflow: hidden;
    visibility: hidden;

    a {
      width: 100%;
      color: #FFF;
      text-decoration: none;
      animation: contact 0.4s ease-in forwards;
      animation-delay: 1.2s;
      opacity: 0;
      transform: translateY(100%);
    }

    &:before {
      content: " ";
      height: 2px;
      background: #FFF;
      margin-left: 5px;
      transition: 0.4s;
      animation: contactLine 0.4s ease-in forwards;
      animation-delay: 1s;
    }
  }

  .home-container-anim {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    z-index: 1;

    canvas {
      z-index: -1;
    }
  }
}

.home-container-small {
  height: 130px;

  .home-slider {
    margin: auto;
  }

  p,
  .home-contact {
    display: none
  }
}

@keyframes contactLine {
  0% {
    width: 0
  }

  100% {
    width: calc(50% - 5px)
  }
}

@keyframes contact {
  0% {
    transform: translateY(100%);
  }

  60% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

// 
// ─── TABLET ───────────────────────────────────────
//
@media screen and (min-width:640px) {
  .home-container {
    .home-slider {
      margin: auto auto 100px 20px;
      max-width: 750px;

      .home-title {

        h1,
        h2 {
          font-size: 40px;
        }
      }
    }

    .home-contact {
      visibility: visible;
    }
  }

  .home-container-small {
    height: 150px;

    .home-slider {
      margin: auto;
    }
  }
}

// 
// ─── DESKTOP ───────────────────────────────────────
//
@media screen and (min-width:1024px) {
  .home-container {
    .home-slider {
      margin: auto auto 20px 20px;
      max-width: 900px;

      .home-title {
        h1 {
          margin: 0;
        }

        h1,
        h2 {
          font-size: 50px;
        }
      }
    }

    .home-contact {
      transform: rotate(90deg);
      width: 220px;
      bottom: 97px;

      a {
        width: 150px;
      }
    }
  }

  .home-container-small {
    height: 120px;

    .home-slider {
      margin: auto;
    }
  }
}