// 
// ─── MOBILE ───────────────────────────────────────
//
.footer {
  background: var(--primary);
  color: var(--secondary);
  padding: 20px;

  &__logo-container {
    svg {
      margin-bottom: 20px;
      width: 100px;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 40px;
    margin: auto;

    ul {
      list-style: none;
      padding: 0;
      margin-bottom: 0;

      li {
        a {
          display: block;
          text-decoration: none;
          color: var(--secondary);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__title {
    text-decoration: underline;
    font-weight: bold;
  }

  &__presentation {
    margin: 0;
  }

  &__social {
    display: flex;
    gap: 8px;
    margin-top: 20px;

    a {
      text-decoration: none;

      svg {
        color: var(--secondary)
      }
    }
  }
}


.footer-dev-infos>span {
  display: block;
  text-align: center;
  padding: 20px 0;
  margin: 0 40px;
}

@media screen and (min-width:500px) {
  .footer {
    &__container {
      grid-template-columns: 50% 1fr;
    }
  }
}

// 
// ─── TABLET ───────────────────────────────────────
//
.footer {
  padding: 40px;
}

@media screen and (min-width:1000px) {
  .footer {
    &__container {
      grid-template-columns: 50% 1fr 1fr;
    }
  }
}

// 
// ─── DESKTOP ───────────────────────────────────────
//
@media screen and (min-width:1200px) {
  .footer {
    font-size: 14px;

    &__container {
      grid-template-columns: 40% 1fr 1fr 23%;
      max-width: 1450px;
    }

    &__logo-container {
      max-width: 1450px;
      margin: auto;
    }
  }
}