.contact-wrapper {
  padding: 20px;

  .contact-container {
    max-width: 500px;
    margin: auto;
  }

  .contact-formular {
    form {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      max-width: 500px;
      margin: auto;
      padding: 20px 0;

      label {
        margin-bottom: 5px;

        span {
          font-size: 13px;
        }
      }

      .formular-input-wrapper {
        display: flex;
        justify-content: space-between;

        input {
          width: 100%;
        }
      }

      input,
      textarea,
      select {
        background: #E6E9EC;
        border: none;
        margin-bottom: 15px;
        width: calc(100% - 12px);
        position: relative;
        padding-left: 10px;
      }

      select {
        width: 100%;
      }

      input,
      select {
        height: 40px;
      }

      textarea {
        height: 80px;
      }

      button {
        max-width: 150px;
      }

      .form-result {
        display: none;
      }

      .form-result-success {
        display: block;
        color: var(--primary);
        margin: 0 0 20px 0;
      }

      .form-result-error {
        display: block;
        color: #fd3030;
        margin: 0 0 20px 0;
      }
    }
  }
}

.contact-wrapper-dark {
  background: var(--secondary);
  color: #FFF;

  p {
    color: var(--text-grey);
  }
}

@media screen and (min-width:1024px) {
  .contact-wrapper {
    padding: 80px 20px;
    display: flex;

    .contact-container {
      max-width: 500px;
      margin: auto;

      .title-container {
        margin-top: 0;
      }
    }

    .contact-formular {
      width: 50%;
    }
  }
}